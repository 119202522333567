import { AuthInfo, Kiosk, Launcher } from '../types';
import {
  applyDocumentClasses,
  inject3rdPartyScript,
  trackAppLaunch,
} from '../support';
import { get, isString, omit } from 'lodash';

import { State } from '../types';
import Vue from 'vue';
import Vuex from 'vuex';
import http from '../services/http';
import router from '../router';
import { MisconfiguredDeviceError } from '@/services/error';
import bugsnag from '@/BugsnagClient';

Vue.use(Vuex);

const KILL_APP_DELAY = 750;

export default new Vuex.Store({
  state: {
    kiosk: undefined,
    brand: undefined,
    store: undefined,
    group: undefined,
    launchers: [],
    launcher: undefined,
    launched: false,
    loading: true,
    active: false,
    initialized: false,
    isAttemptingLogout: false,
  } as State,
  getters: {
    hasKioskInfo: (state): boolean => state.kiosk !== undefined,
    primaryApp: (state): Launcher | undefined =>
      (state.launchers || []).find((launcher: Launcher) => launcher.primary),
    otherApps: (state): Launcher[] =>
      (state.launchers || []).filter((launcher: Launcher) => !launcher.primary),
    screenSaverURL: (state): string | undefined => {
      const screenSaverURL = state.kiosk?.screen_saver_url;

      if (!isString(screenSaverURL) || screenSaverURL.length === 0) {
        return undefined;
      }

      return screenSaverURL;
    },
    hasScreenSaver: (_, getters): boolean =>
      getters.screenSaverURL !== undefined,
  },
  mutations: {
    attemptLogout(state, isAttempting: boolean) {
      state.isAttemptingLogout = isAttempting;
    },
    initialized(state) {
      state.initialized = true;
    },
    initialize(state, data: AuthInfo) {
      state.kiosk = omit(data, ['store', 'departments', 'launchers']) as Kiosk;
      state.launchers = get(data, 'launchers.data', []);
      state.store = omit(data.store, ['brand', 'group']);
      state.brand = data.store.brand;
      state.group = data.store.group;
    },
    setActive(state, isActive = true) {
      state.active = isActive;
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setLauncher(state, launcher: Launcher | undefined = undefined) {
      state.launcher = launcher;
    },
    setLaunched(state, launched: boolean) {
      state.launched = launched;
    },
  },
  actions: {
    async initialize({ commit, getters }) {
      let data;

      commit('setLoading', true);

      try {
        await http.get(process.env.VUE_APP_HEARTBEAT_URL);

        const response = await http.get(process.env.VUE_APP_AUTH_URL);

        data = response.data;

        const brand = data?.store?.brand || {};

        if (
          brand.identifier &&
          process.env.VUE_APP_BRAND_IDENTIFIER &&
          brand.identifier.toLowerCase() !==
            process.env.VUE_APP_BRAND_IDENTIFIER.toLowerCase()
        ) {
          // the brand of the logged in kiosk account should not be using this build
          // of the app! This usually happens when a Kiosk Pro install is misconfigured.
          // Try redirecting to the correct URL.
          throw new MisconfiguredDeviceError(brand.identifier);
        }

        if (data.launchers.length === 0) {
          window.location.href = process.env.VUE_APP_WEBAPP_URL;
          return;
        }

        commit('initialize', data);
        commit('setActive', !getters.hasScreenSaver);
        commit('setLoading', false);
        applyDocumentClasses();
      } finally {
        commit('setLoading', false);
      }
    },
    resetDueToInactivity({ dispatch, commit, getters }) {
      commit('setActive', !getters.hasScreenSaver);
      dispatch('reset');
    },
    reset({ commit, state }) {
      commit('setLaunched', false);

      bugsnag.leaveBreadcrumb('close launched app', undefined, 'navigation');

      if (state.launcher) {
        inject3rdPartyScript(state.launcher.close_script_url);
      }

      setTimeout(() => commit('setLauncher'), KILL_APP_DELAY);
    },
    reboot() {
      this.dispatch('reset');
      http
        .get(process.env.VUE_APP_HEARTBEAT_URL)
        .then(() => (window.location.href = '/'))
        .catch(() =>
          router.push({
            name: 'ServiceUnavailable',
            query: { c: String(Date.now()) },
          })
        );
    },
    async launch({ commit, state }, launcher) {
      commit('setLauncher', launcher);
      commit('setLaunched', true);

      inject3rdPartyScript(launcher.open_script_url);

      trackAppLaunch(
        launcher.name,
        state.store?.wakefern_id,
        state.store?.name,
        state.group?.name,
        state.kiosk?.username
      );
    },
  },
  modules: {},
});
