












import Vue from 'vue';
import { Launcher } from '../types';
import { mapActions } from 'vuex';

export default Vue.extend({
  props: {
    launcher: {
      type: Object as () => Launcher,
      required: true,
    },
  },
  methods: {
    ...mapActions(['launch']),
  },
});
