import LaunchPad from '../views/LaunchPad.vue';
import ServiceUnavailable from '../views/ServiceUnavailable.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import { isDevelopment } from '@/utilities';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: LaunchPad,
  },
  {
    path: '/service-unavailable',
    name: 'ServiceUnavailable',
    component: ServiceUnavailable,
  },
];

const router = new VueRouter({
  mode: isDevelopment() ? 'hash' : 'history',
  base: process.env.BASE_URL || '/',
  routes,
});

router.beforeEach((_, __, next) => {
  next();

  store.commit('setLoading', false);
});

export default router;
