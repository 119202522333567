


























































































import Vue from 'vue';
import FocusLock from 'vue-focus-lock';

export default Vue.extend({
  props: {
    heading: {
      required: true,
      type: String,
    },
    ariaHeading: {
      required: false,
      type: String,
    },
    ariaDescription: {
      required: true,
      type: String,
    },
  },

  components: {
    FocusLock,
  },
});
