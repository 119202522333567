// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import App from './App.vue';
import Vue from 'vue';
import { attemptReboot } from './support';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { initializeAndVerify } from './initialize';
import VueRouter from 'vue-router';
import bugsnag from '@/BugsnagClient';
import PortalVue from 'portal-vue';

require('./assets/css/app.scss');

library.add(faChevronLeft);
library.add(faTimes);

Vue.use(PortalVue);

Vue.config.productionTip = false;

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

// attempt reboot every 4 hours
setInterval(() => attemptReboot(4), 1000 * 60);

new Vue({
  async created() {
    // attempt to fetch kiosk & launcher data and populate the state
    try {
      await initializeAndVerify();
    } catch (error: any) {
      // if something goes wrong, check if it's just a NavigationDuplicated from VueRouter
      if (VueRouter.isNavigationFailure(error)) {
        return;
      }

      // when it's not, rethrow
      bugsnag.notify(error);
    }
  },
  router,
  store,
  render: h => h(App),
}).$mount('#app');
