import { MisconfiguredDeviceError } from './services/error';
import URI from 'urijs';
import store from './store';
import { RawLocation } from 'vue-router';
import router from './router';
import { AxiosError } from 'axios';

/**
 * When the launcher is found to be running on the wrong domain for the brand, this
 * function will build and return the correct domain based on the brand and environment.
 *
 * @param error
 * @return string | undefined
 */
const getRedirectURLForMisconfiguredDevice = (
  error: MisconfiguredDeviceError
): string | undefined => {
  let uri: URI | undefined = undefined;

  switch (error.brandIdentifier) {
    case 'shoprite':
      uri = new URI({ hostname: 'shoprite.com' }).protocol('https');
      break;
    case 'fresh-grocer':
      uri = new URI({ hostname: 'thefreshgrocer.com' });
      break;
    case 'dearborn':
      uri = new URI({ hostname: 'dearbornmarket.com' }).protocol('https');
      break;
    case 'price-rite':
      uri = new URI({ hostname: 'priceritemarketplace.com' }).protocol('https');
      break;
    case 'fairway-market':
      uri = new URI({ hostname: 'fairwaymarket.com' }).protocol('https');
      break;
    case 'gourmet-garage':
      uri = new URI({ hostname: 'gourmetgarage.com' }).protocol('https');
      break;
  }

  if (uri === undefined) {
    // the brand being rendered is unknown by the switch above. no redirection
    // away from the launcher should occur
    return undefined;
  }

  if (process.env.VUE_APP_ENV === 'staging') {
    uri.protocol('http').subdomain('stage2.deliorder-launcher');
  } else {
    uri.subdomain('deliorder-launcher');
  }

  return uri.toString();
};

interface URIDestination {
  type: 'uri';
  value: string;
}

interface RouteDestination {
  type: 'route';
  value: RawLocation;
}

type InitializationDestination = RouteDestination | URIDestination;

/**
 * Type guard to verify an error as coming from Axios or not.
 *
 * @param error
 * @return boolean
 */
const isAxiosError = (error: Error): error is AxiosError =>
  (error as AxiosError).isAxiosError === true;

/**
 * Figure out where and how to redirect the browser (internally via VueRouter or
 * externally via window.location) based on the type of error passed.
 *
 *  - If Axios got a 401 Unauthorized, the destination will be a window.location
 *    redirect back to to the admin site login screen
 *  - If the launcher is running on the wrong domain for the brand it's configured
 *    for, the destination will be a window.location redirect to the correct domain
 *  - if the API is found to be offline or something else went wrong, the destination
 *    will be a VueRouter redirect to the 'service unavailable' screen
 *
 * @param error
 */
const getDestinationFromError = (
  error: Error | MisconfiguredDeviceError | AxiosError
): InitializationDestination | undefined => {
  if (error instanceof MisconfiguredDeviceError) {
    const uri = getRedirectURLForMisconfiguredDevice(error);

    if (uri === undefined) {
      return undefined;
    }

    return {
      type: 'uri',
      value: uri,
    };
  }

  if (isAxiosError(error) && error?.response?.status === 401) {
    // the app isn't logged into a kiosk account; abort immediately by redirecting
    // back to the main login page
    return {
      type: 'uri',
      value: process.env.VUE_APP_REAUTH_URL,
    };
  }

  return {
    type: 'route',
    value: { name: 'ServiceUnavailable' },
  };
};

/**
 * Try to initialize the app. based on the content of a caught error the app should
 * redirect or render a different route. If there is no error, the app renders as expected.
 */
export const initializeAndVerify = async () => {
  let routeLocation: RawLocation = { path: '/' };

  try {
    // try to initialize the app
    await store.dispatch('initialize');
  } catch (error: any) {
    // something went wrong. Figure out if we need to redirect to a different brand,
    // re-auth, or service unavailable screen
    const destination = getDestinationFromError(error);

    if (destination?.type === 'uri') {
      // its a full redirect to either another brand or re-auth
      window.location.replace(destination.value);

      return;
    }

    if (destination?.type === 'route') {
      // its a local redirect (service unavailable)
      routeLocation = destination.value;
    }
  }

  store.commit('initialized');

  // everything initialized properly
  await router.replace(routeLocation);
};
