import { isEmpty, memoize } from 'lodash';
import store from './store';
import bugsnag from '@/BugsnagClient';

export const applyDocumentClasses = () => {
  let classes = [];

  if (store.state.brand) {
    classes.push(store.state.brand.identifier);
  }

  classes = classes.filter((v: string): boolean => !isEmpty(v));

  const html = document.querySelector('html');

  if (html) {
    html.classList.add(...classes);
  }
};

export const attemptReboot = (hours: number): void => {
  const now = new Date();

  if (now.getMinutes() !== 0) {
    return;
  }

  if (now.getHours() % hours !== 0) {
    return;
  }

  store.dispatch('reboot');
};

/**
 * Convert the iframe injection jsonp-style callback into a Promise-based, await-able
 * function. Memoize the Promise from the initial call so that when it resolves
 * once, later app launches will reuse the resolved promise.
 */
const getKioskProId = memoize(() =>
  Promise.race([
    new Promise(resolve => {
      if (window.kioskpro_hooks_available !== 1) {
        console.warn(
          'The Kiosk Pro functions have not been loaded. The kiosk id could not be fetched for GA tracking.'
        );

        resolve(null);

        return;
      }

      window.kp_requestKioskId_callback = (kioskId: string) => {
        console.info('Kiosk Pro responded with id: ', kioskId);
        resolve(isEmpty(kioskId) ? null : kioskId);
      };

      try {
        window.kp_requestKioskId('kp_requestKioskId_callback');
      } catch (error) {
        console.warn(
          'Could not fetch Kiosk Pro unique id. Is the app running outside of Kiosk Pro?'
        );
        resolve(null);
      }
    }),
    new Promise(resolve =>
      setTimeout(() => {
        console.info('KioskPro failed to respond. Moving on...');
        resolve(null);
      }, 2000)
    ),
  ])
);

export const trackAppLaunch = async (
  appName: string,
  storeNumber: number | undefined,
  storeName: string | undefined,
  groupName: string | undefined,
  kioskId: string | undefined
) => {
  const kioskProId = await getKioskProId();

  console.info('Tracking App Launch: ', {
    appName,
    storeNumber,
    storeName,
    groupName,
    kioskProId,
    kioskId,
  });

  bugsnag.leaveBreadcrumb(
    'launch app',
    {
      launcher_name: appName,
      wakefern_id: storeNumber,
      store_name: storeName,
      group_name: groupName,
      kiosk_username: kioskId,
    },
    'navigation'
  );

  if (
    !window.dataLayer ||
    storeNumber === undefined ||
    storeName === undefined ||
    groupName === undefined
  ) {
    // either GA tracking is unavailable or some data is missing for a good tracking event
    return;
  }

  window.dataLayer?.push({
    event: 'gtm1.send',
    appName: appName,
    storeNumber: storeNumber, // store number
    storeName: storeName, // store name
    groupName: groupName, // group name (member name)
    kioskAccount: kioskProId ?? 'unset', // kiosk pro uniq id
    kioskUsername: kioskId, // deli & more kiosk account id
  });
};

export const inject3rdPartyScript = (url: string | null) => {
  const id = 'js-injected-script';
  const $existing = document.querySelector(`#${id}`);

  if ($existing && $existing.parentNode) {
    $existing.parentNode.removeChild($existing);
  }

  if (!url) {
    return;
  }

  const $script = document.createElement('script');
  $script.type = 'text/javascript';
  $script.src = url;
  $script.setAttribute('id', id);

  document.body.appendChild($script);
};
