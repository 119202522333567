



























import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  mounted() {
    const $video = this.$el.querySelector('video');

    if (!$video) {
      return;
    }

    $video.addEventListener(
      'canplay',
      () =>
        (this.orientation =
          $video.videoHeight <= $video.videoWidth ? 'landscape' : 'portrait')
    );
  },

  data() {
    return {
      orientation: 'landscape',
    };
  },

  computed: {
    ...mapState(['kiosk']),

    isOrientedAsLandscape(): boolean {
      return this.orientation === 'landscape';
    },
  },

  methods: {
    notifyActivity() {
      setTimeout(() => this.$emit('change'), 400);
    },
  },
});
