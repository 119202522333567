<template>
  <div class="flex flex-col h-full">
    <div class="text-center py-32 space-y-6">
      <h1 class="text-6xl font-bold leading-none font-optima text-gray-600">
        We'll Be Right Back!
      </h1>
      <p>
        This kiosk will attempt to reload in
        <strong v-text="remaining"></strong>.
      </p>
    </div>
    <div class="p-6 border-t border-gray-600 bg-gray-300 text-center h-full">
      <button
        @click="attemptReboot"
        class="
          rounded
          bg-gray-800
          font-bold font-din
          text-xl
          focus:bg-gray-900
          hover:bg-gray-900
          text-white
          font-bold
          py-4
          px-6
        "
      >
        Reload Now
      </button>
    </div>
  </div>
</template>

<script>
import { delay } from 'lodash';
import { initializeAndVerify } from '@/initialize';

let timer;
const MIN = 30;
const MAX = 30;
const countdown = () => Math.ceil(Math.random() * MIN + (MAX - MIN));

export default {
  mounted() {
    this.$nextTick(this.start);
  },

  destroyed() {
    this.$nextTick(() => clearInterval(timer));
  },

  watch: {
    $route() {
      this.start();
    },
  },

  data() {
    return {
      next_attempt: countdown(),
    };
  },

  computed: {
    remaining() {
      const left = Math.max(this.next_attempt, 0);

      return `${left} second${left === 1 ? '' : 's'}`;
    },
  },

  methods: {
    start() {
      clearInterval(timer);

      this.next_attempt = countdown();

      this.decrement();
    },

    async decrement() {
      if (--this.next_attempt === 0) {
        await this.attemptReboot();

        return;
      }

      timer = delay(this.decrement, 1000);
    },

    async attemptReboot() {
      try {
        await initializeAndVerify();
      } catch (error) {
        this.start();
      }
    },
  },
};
</script>
