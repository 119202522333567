








import Vue from 'vue';
import Launchers from '../components/Launchers.vue';
import LaunchedApp from '../components/LaunchedApp.vue';
import { mapState, mapMutations, mapGetters } from 'vuex';
import store from '../store';
import ScreenSaver from '../components/ScreenSaver.vue';

window.KioskShouldDisableIdleTimer = function () {
  // close out the active app and show the screensaver
  store.dispatch('resetDueToInactivity');

  // Tell kiosk pro to skip the idle timer
  return 'YES';
};

export default Vue.extend({
  computed: {
    ...mapState(['kiosk', 'loading', 'active']),
    ...mapGetters(['screenSaverURL']),

    shouldShowScreenSaver(): boolean {
      if (!this.screenSaverURL) {
        return false;
      }

      return !this.active;
    },
  },

  methods: {
    ...mapMutations(['setActive']),
  },

  components: {
    ScreenSaver,
    Launchers,
    LaunchedApp,
  },
});
