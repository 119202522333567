<template>
  <div class="mask">
    <div class="sk-wave">
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
    </div>
  </div>
</template>

<script>
export default {
  //
};
</script>

<style scoped lang="scss">
.mask {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

$sk-size: 40px;
$sk-color: white;

.sk-wave {
  width: $sk-size;
  height: $sk-size;
  display: flex;
  justify-content: space-between;
}

.sk-wave-rect {
  background-color: $sk-color;
  height: 100%;
  width: 15%;
  animation: sk-wave 1.2s infinite ease-in-out;
}

.sk-wave-rect:nth-child(1) {
  animation-delay: -1.2s;
}
.sk-wave-rect:nth-child(2) {
  animation-delay: -1.1s;
}
.sk-wave-rect:nth-child(3) {
  animation-delay: -1s;
}
.sk-wave-rect:nth-child(4) {
  animation-delay: -0.9s;
}
.sk-wave-rect:nth-child(5) {
  animation-delay: -0.8s;
}

@keyframes sk-wave {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
</style>
