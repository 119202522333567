


































































import Vue from 'vue';
// @ts-ignore: Types do not exist for this package
import { Carousel, Slide } from 'vue-carousel';
import Launcher from './Launcher.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import HiddenInPlainSightLogout from './HiddenInPlainSightLogout.vue';

export default Vue.extend({
  data() {
    return {
      launcherPage: 0,
    };
  },

  computed: {
    ...mapState(['launched', 'brand']),
    ...mapGetters(['primaryApp', 'otherApps']),

    numberOfPages(): number {
      return Math.ceil(this.otherApps.length / 4);
    },

    hasPrimaryImage(): boolean {
      return String(this.primaryApp?.primary_image_url || '').length > 0;
    },

    gridClasses(): string {
      switch (this.otherApps.length) {
        case 1:
        case 2:
        case 3:
          return 'grid-cols-3 gap-12';
        case 4:
          return 'grid-cols-4 gap-8';
        case 5:
        default:
          return 'grid-cols-5 gap-6';
      }
    },

    fontSizeClasses(): string {
      switch (this.otherApps.length) {
        case 1:
        case 2:
        case 3:
          return 'text-2xl';
        case 4:
          return 'text-xl';
        case 5:
          return '';
        default:
          return 'text-sm';
      }
    },
  },

  methods: {
    ...mapActions(['launch']),

    incrementLauncherPage() {
      if (this.launcherPage >= this.numberOfPages - 1) {
        this.launcherPage = 0;
      } else {
        this.launcherPage++;
      }
    },

    decrementLauncherPage() {
      if (this.launcherPage <= 0) {
        this.launcherPage = this.numberOfPages;
      } else {
        this.launcherPage--;
      }
    },
  },

  components: {
    Launcher,
    HiddenInPlainSightLogout,
    Carousel,
    Slide,
  },
});
