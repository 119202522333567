





















































import Vue from 'vue';
import { mapState } from 'vuex';
import ModalBox from './ModalBox.vue';

const PASSCODE = 'kiosklogout';

const CLICK_REQUIREMENT = 5;
const CLICK_WINDOW_MS = 3 * 1000;

declare module 'vue/types/vue' {
  interface Vue {
    $timer: number;
  }
}

export default Vue.extend({
  watch: {
    isAttemptingLogout(value) {
      this.password = '';

      if (value) {
        this.$nextTick(() =>
          (this.$refs.password as HTMLInputElement)?.focus()
        );
      }
    },
  },

  data() {
    return {
      password: '',
      trackedClicks: 0,
    };
  },

  computed: {
    ...mapState(['isAttemptingLogout']),
  },

  methods: {
    trackOpenClick() {
      if (this.trackedClicks === 0) {
        this.$timer = setTimeout(
          () => (this.trackedClicks = 0),
          CLICK_WINDOW_MS
        );
      }

      this.trackedClicks = this.trackedClicks + 1;

      if (this.trackedClicks >= CLICK_REQUIREMENT) {
        this.$store.commit('attemptLogout', true);
        this.trackedClicks = 0;
        clearTimeout(this.$timer);
      }
    },

    closeModal() {
      this.$store.commit('attemptLogout', false);
    },

    performLogoutAttempt() {
      if (this.password.toLowerCase().trim() === PASSCODE.toLowerCase()) {
        window.location.href = process.env.VUE_APP_REAUTH_URL;
      }

      this.closeModal();
    },
  },

  components: {
    ModalBox,
  },
});
