import Bugsnag, { Client, Event } from '@bugsnag/js';

import store from './store';

/**
 * Define the subset of @bugsnag/js Client that we actually use to make typing
 * a mock of it straightforward.
 */
type ActsLikeBugsnag = Pick<
  Client,
  'addMetadata' | 'clearMetadata' | 'notify' | 'leaveBreadcrumb' | 'setUser'
>;

/**
 * When no API key is provided, a mock Bugsnag client is created.
 */
let bugsnagClient: ActsLikeBugsnag = {
  addMetadata() {
    // do nothing
  },
  clearMetadata() {
    // do nothing
  },
  notify() {
    // do nothing
  },
  leaveBreadcrumb() {
    // do nothing
  },
  setUser() {
    // do nothing
  },
};

/**
 * Only when an API key has been provided will a regular Bugsnag client instance
 * be instantiated.
 */
if ((process.env.VUE_APP_BUGSNAG_KEY || '').length > 0) {
  bugsnagClient = Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_KEY,
    onError(event: Event) {
      event.addMetadata('state', JSON.parse(JSON.stringify(store.state)));

      event.app.version = process.env.VUE_APP_VERSION || 'unknown';
      event.app.releaseStage = process.env.VUE_APP_ENV || 'development';

      return true;
    },
  });
}

const bugsnagExport = bugsnagClient;

export default bugsnagExport;
