








import Vue from 'vue';
import Loader from './components/Loader.vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'app',

  computed: {
    ...mapState(['loading', 'initialized']),
  },

  components: {
    Loader,
  },
});
