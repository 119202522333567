
































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { connectToChild } from 'penpal';

const BLANK_PAGE = 'about:blank';

// collect all kp_... functions set on the window by the kiosk pro include.
const kioskProAPI = Object.keys(window)
  .filter(fn => /^kp_/.test(fn))
  .reduce(
    (acc, fn) => ({ [fn]: (window as { [key: string]: any })[fn], ...acc }),
    {}
  );

export default Vue.extend({
  watch: {
    url(value: string) {
      window.OrderExpress.penpalConnection?.destroy();
      window.OrderExpress.penpalConnection = null;

      if (value !== BLANK_PAGE) {
        // if the iframe is about to render a new app, establish a new penpal connection
        window.OrderExpress.penpalConnection = connectToChild({
          iframe: this.$el.querySelector('iframe') as HTMLIFrameElement,
          childOrigin: this.url,
          methods: kioskProAPI,
        });
      }
    },
  },

  computed: {
    ...mapState(['launcher']),

    url(): string {
      return this.launcher?.url ?? BLANK_PAGE;
    },
  },

  methods: {
    ...mapActions(['reset']),
  },
});
